import React, { createContext, useContext, useState } from "react";

const UserConfigContext = createContext();

const defaultConfig = {};

const UserConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultConfig);

  const updateConfig = (newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  return (
    <UserConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </UserConfigContext.Provider>
  );
};

const useUserConfig = () => {
  const context = useContext(UserConfigContext);
  if (!context) {
    throw new Error("context is not being used properly");
  }
  return context;
};

export { UserConfigProvider, useUserConfig };
